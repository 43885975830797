<template>
  <el-dialog :title="editType=='add' ? '新增学校类型':editType == 'show' ?'查看学校类型':'编辑学校类型信息'" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="420px">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="22">
          <el-form-item label="名称" prop="name">
            <el-input placeholder="最长20位" v-model="form.name" maxlength="20" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="22">
          <el-form-item label="编号" >
            <el-input placeholder="最长20位" v-model="form.code" maxlength="20" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>
        
        
        <el-col :span="22">
          <el-form-item label="备注" prop="orgDate">
           <el-input type='textarea' placeholder="最长200位" v-model="form.remark" maxlength="200" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-button @click="dialogClose">关闭</el-button>
          <el-button type="primary" v-debounce="onSubmit" v-if="!editShow">保存</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>

import { addSchoolType, updateSchoolType } from '@/api/index'
export default {
  name: 'BrandForm',
  components: {  },
  props: ['value', 'editRow', 'editType'],
  data() {
    return {
      form: {
        id:'',
        name: '',//名称
        code:'',
        remark: '', // 备注
        type: '', // 类型
      },
      rules: {
        name: [
          { required: true, message: '名称不可为空', trigger: 'blur' }
        ],
      },

    }
  },
  computed: {
    editShow() {
      return this.editType == 'show'
    }
  },
  created() {
    if (this.editType != 'add') {
      Object.assign(this.form, this.editRow)
    }
  },
  methods: {
    dialogClose(type) {
      if (type == 1) {
        this.$emit('success')
      }
      this.$emit('input', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      let success = response => {
        if (response.code == 200) {
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.dialogClose(1);
        } else {
          this.$message.error({
            message: response.msg || response.error_msg,
            duration: 1500
          });
        }
      }
      if (this.editType == 'edit') {
        updateSchoolType(form).then(success)
      } else {
        addSchoolType(form).then(success)
      }
    }
  },

}
</script>