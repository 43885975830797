<template>
  <div style="width:100%">
      <el-button type="primary" @click="editDetail('', 'add')" size="small">新增分类</el-button>
       <el-button type="primary" @click="goFastAdd()" size="small">快速新增</el-button>
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名称、编号"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" stripe>
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="名称" align="center" show-overflow-tooltip width="250">
        <template slot-scope="scope">
          <span class="table-text" @click="editDetail(scope.row, 'show')">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="编号" align="center" show-overflow-tooltip prop="code" width="100"></el-table-column>    
      <el-table-column label="年级数量" align="center" prop="gradeCount" width="100"></el-table-column> 
      <el-table-column label="年级" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          <span class="table-text" @click="goSetGrade(scope.row)">{{scope.row.gradeNameSplicing==null?'未设置':scope.row.gradeNameSplicing}}</span>
        </template>
        
        </el-table-column> 
      <el-table-column label="备注" prop="remark" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="创建时间" prop="createTime" align="center" width="180"></el-table-column>
      <el-table-column align="center" label="操作" width="260">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row,'edit')">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" style="color:red" @click="deleteOne(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>


    <SchoolTypeForm v-if="isAddDialog" v-model="isAddDialog" :editRow="editRow" :editType="editType"  @success="getList"></SchoolTypeForm>
    <SchoolTypeFastForm v-if="isFastAddDialog" v-model="isFastAddDialog"  @success="getList"></SchoolTypeFastForm>

  
      
  </div>
</template>

<script>
import SchoolTypeForm from './SchoolTypeForm'
import SchoolTypeFastForm from './SchoolTypeFastForm'
import { Confirm } from '@/utils/common.js'
import { deleteSchoolType, getSchoolTypeList } from '@/api/index.js'
export default {
  name: 'schoolTypeList',
  components: {  SchoolTypeForm,SchoolTypeFastForm},
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      isFastAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
      },
      list: [],
      total: 0,
      editRow: '',
      editType: ''
    }
    
  },
  created() {
    this.getList();
  },
  methods: {
    goSetGrade(schoolTypeInfo){
      this.$emit('changeShowType', 'grade',schoolTypeInfo)
    },
    editDetail(editRow, editType) {
      this.editRow = editRow;
      this.isAddDialog = true;
      this.editType = editType;
    },
    goFastAdd(){
      this.isFastAddDialog = true;
    },
    getList() {
      getSchoolTypeList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    deleteOne(id) {
      Confirm('确定删除该类型吗?', () => {
        deleteSchoolType({ ids:id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
  }
}
</script>

<style>
</style>