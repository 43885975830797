<template>
  <div style="width:100%">
      <el-button type="primary" @click="editDetail('', 'add')" size="small">新增年级</el-button>
    <div class="search-box">
      <el-form inline size="small">
        

        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-back" @click="goBackSchoolTypeList()">返回学校分类列表</el-button>
        </el-form-item>
        <el-form-item label="当前学校分类名称：">
          {{schoolTypeInfo.name}}
        </el-form-item>
        
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" stripe>
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="年级名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="table-text" @click="editDetail(scope.row, 'show')">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="编号" align="center" show-overflow-tooltip prop="code"></el-table-column>    
      <el-table-column label="班级数量" align="center" prop="classCount"></el-table-column> 
      <el-table-column label="班级" align="center" show-overflow-tooltip >          
        <template slot-scope="scope">
          <span class="table-text" @click="goSetClass(scope.row, 'show')">{{scope.row.classNameSplicing==null?'未设置':scope.row.classNameSplicing}}</span>
        </template>
        
        </el-table-column>
        <el-table-column label="显示顺序" align="center" prop="sort"></el-table-column> 
      <el-table-column label="备注" prop="remark" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="创建时间" prop="createTime" align="center" ></el-table-column>
      <el-table-column align="center" label="操作" width="260">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row,'edit')">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" style="color:red" @click="deleteOne(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>


    <GradeForm v-if="isAddDialog" v-model="isAddDialog" :editRow="editRow" :schoolTypeId="form.schoolTypeId" :editType="editType"  @success="getList"></GradeForm>

  
      
  </div>
</template>

<script>
import GradeForm from './GradeForm'
import { Confirm } from '@/utils/common.js'
import { deleteGrade, getGradeList } from '@/api/index.js'
export default {
  name: 'gradeName',
  components: {  GradeForm},
  props: ['parentSchoolTypeInfo'],
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      isFastAddDialog:false,
      form: {
        isPage: 1,
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        schoolTypeId:''
      },
      schoolTypeInfo:{},
      list: [],
      total: 0,
      editRow: '',
      editType: ''
    }
    
  },
  created() {
    Object.assign(this.schoolTypeInfo, this.parentSchoolTypeInfo);
    this.form.schoolTypeId = this.schoolTypeInfo.id;
    this.getList();
  },
  methods: {
    goBackSchoolTypeList(){
        this.$emit('changeShowType', 'list')
    },
    goSetClass(gradeInfo){
        this.$emit('changeShowType', 'class',gradeInfo)
    },
    editDetail(editRow, editType) {
      this.editRow = editRow;
      this.isAddDialog = true;
      this.editType = editType;
    },
    goFastAdd(){
      this.isFastAddDialog = true;
    },
    getList() {
      getGradeList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    deleteOne(id) {
      Confirm('确定删除该年级吗?', () => {
        deleteGrade({ ids:id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
  }
}
</script>

<style>
</style>