<template>
  <el-dialog :title="'快速新增学校分类'" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="580px">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="22">
          <el-form-item label="分类名称" prop="schoolTypeName">
            <el-input placeholder="请输入分类名称" v-model="form.schoolTypeName" maxlength="20" ></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="22" >
          <el-form-item label="年级" prop="gradeName">
            <el-input placeholder="多个以逗号分隔" v-model="form.gradeName"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="22">
          <el-form-item label="班级" prop="className">
            <el-input placeholder="多个以逗号分隔" v-model="form.className" ></el-input>
          </el-form-item>
        </el-col>
        

        <el-col :span="24">
          <el-button @click="dialogClose">关闭</el-button>
          <el-button type="primary" v-debounce="onSubmit" >保存</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>

import { quickAddSchoolType } from '@/api/index'
export default {
  name: 'BrandForm',
  components: {  },
  props: ['value', 'editRow', 'editType'],
  data() {
    return {
      form: {
        schoolTypeName: '',//名称
        className: '', // 班级
        gradeName:'',//年级
      },
      rules: {
        schoolTypeName: [
          { required: true, message: '名称不可为空', trigger: 'blur' }
        ],
        gradeName: [
          { required: true, message: '年级不可为空', trigger: 'blur' }
        ],
        className: [
          { required: true, message: '班级不可为空', trigger: 'blur' }
        ],
      },

    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    dialogClose(type) {
      if (type == 1) {
        this.$emit('success')
      }
      this.$emit('input', false)
    },
    onSubmit() {
      this.form.className = this.form.className.trim();
      this.form.gradeName = this.form.gradeName.trim();
      if (!this.$validate('form')) return
      let gradeNameArr = this.form.gradeName.split(',');
      let isRepeatGrade = false;
      for(let index =0;index<gradeNameArr.length;index++){
        let currGradeName = gradeNameArr[index];
        for(let newIndex =index+1; newIndex<gradeNameArr.length;newIndex++){
          let newGradeName = gradeNameArr[newIndex];
          if(currGradeName == newGradeName){
            isRepeatGrade = true;
            break;
          }
        }
        if(isRepeatGrade){
          this.$message.error({
            message: '年级名称为：'+currGradeName+" 重复了！",
            duration: 2000
          });
          return false;
        }
      }


      let classNameArr = this.form.className.split(',');
      let isRepeatClass = false;
      for(let index =0;index<classNameArr.length;index++){
        let currClassName = classNameArr[index];
        for(let newIndex =index+1; newIndex<classNameArr.length;newIndex++){
          let newClassName = classNameArr[newIndex];
          if(currClassName == newClassName){
            isRepeatClass = true;
            break;
          }
        }
        if(isRepeatClass){
          this.$message.error({
            message: '班级名称为：'+currClassName+" 重复了！",
            duration: 2000
          });
          return false;
        }
      }




      let form = JSON.parse(JSON.stringify(this.form));
      let success = response => {
        if (response.code == 200) {
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.dialogClose(1);
        } else {
          this.$message.error({
            message: response.msg || response.error_msg,
            duration: 1500
          });
        }
      }

      quickAddSchoolType(form).then(success);

    }
  },

}
</script>