<template>
  <div style="width:100%">
      
    <SchoolTypeList v-if="showType == 'list'" @changeShowType="changeShowType"></SchoolTypeList>
    <GradeName v-if="showType == 'grade'"  @changeShowType="changeShowType" :parentSchoolTypeInfo="schoolTypeInfo"></GradeName>
    <ClassName v-if="showType == 'class'"  @changeShowType="changeShowType" :parentSchoolTypeInfo="schoolTypeInfo" :parentGradeInfo="gradeInfo"></ClassName>
      
  </div>
</template>

<script>
import SchoolTypeList from './components/SchoolTypeList'
import GradeName from './components/GradeName'
import ClassName from './components/ClassName'
import {  } from '@/api/index.js'
export default {
  name: 'schoolType',
  components: {  SchoolTypeList,GradeName,ClassName},
  data() {
    return {
      showType:'list',
      schooltypeInfo:{},
      gradeInfo:{}
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,dataInfo){
      if(type=='grade'){
        this.schoolTypeInfo = dataInfo;
      }else if(type == 'class'){
        this.gradeInfo = dataInfo;
      }
      this.showType = type;
    }
  }
}
</script>

<style>
</style>